import './App.css';
import Blog from "./components/Blog"

function App() {
  return (
    <div className="App">
      <Blog></Blog>
    </div>
  );
}

export default App;
